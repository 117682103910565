import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"editCourseAddress",staticClass:"custom-form pt-2",attrs:{"id":"editCourseAddress"},on:{"submit":function($event){$event.preventDefault();return _vm.updateAddress.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{staticClass:"pt-8",attrs:{"cols":"12"}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getFieldMask('phoneNumber')),expression:"getFieldMask('phoneNumber')"}],attrs:{"outlined":"","label":"Numer telefonu","rules":[_vm.rules.required, _vm.rules.phoneNumber],"placeholder":"Wpisz numer telefonu"},model:{value:(_vm.address.phoneNumber),callback:function ($$v) {_vm.$set(_vm.address, "phoneNumber", $$v)},expression:"address.phoneNumber"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","rows":3,"auto-grow":"","label":"Notatka do lokalizacji","hide-details":"","placeholder":"Wpisz notatkę"},model:{value:(_vm.address.notes),callback:function ($$v) {_vm.$set(_vm.address, "notes", $$v)},expression:"address.notes"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"type":"submit","form":"editCourseAddress"}},[_vm._v(" Edytuj ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }